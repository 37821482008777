import React, { Component } from 'react';
import { render, hydrate } from 'react-dom';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import App from './App.js';
import { StateProvider } from './store.js';

import './styles/base.css';

const rootElement = document.getElementById('root');
const app = (
	<StateProvider>
		<Router>
			<App />
		</Router>
	</StateProvider>
);

if (rootElement.hasChildNodes()) {
	hydrate(app, rootElement);
} else {
	render(app, rootElement);
}
