import React from 'react';
import classnames from 'classnames';

import css from './Logo.css';
export const Logo = ({ offset = 0, className }) => {
	return (
		<div className={classnames(css.root, { [className]: className })}>
			<svg className={css.logo} viewBox="0 0 2000 569" fill="none" xmlns="http://www.w3.org/2000/svg">
				<title>001</title>
				<g transform={`translate(640 0)`}>
					<path
						transform={`translate(${offset * -1} 0)`}
						d="M362.5 152C362.5 68.8383 427.909 1.5 508.5 1.5C589.091 1.5 654.5 68.8383 654.5 152V427C654.5 510.028 589.227 567.5 508.5 567.5C427.909 567.5 362.5 500.162 362.5 417V152Z"
						stroke="white"
						strokeWidth="3"
					/>
					<path
						transform={`translate(${offset * -1} 0)`}
						d="M4.5 152C4.5 68.8383 69.9086 1.5 150.5 1.5C231.091 1.5 296.5 68.8383 296.5 152V427C296.5 510.028 231.227 567.5 150.5 567.5C69.9086 567.5 4.5 500.162 4.5 417V152Z"
						stroke="white"
						strokeWidth="3"
					/>
					<path
						transform={`translate(${offset} 0)`}
						d="M359.5 152C359.5 68.8383 424.909 1.5 505.5 1.5C586.091 1.5 651.5 68.8383 651.5 152V427C651.5 510.028 586.227 567.5 505.5 567.5C424.909 567.5 359.5 500.162 359.5 417V152Z"
						stroke="white"
						strokeWidth="3"
					/>
					<path
						transform={`translate(${offset} 0)`}
						d="M1.5 152C1.5 68.8383 66.9086 1.5 147.5 1.5C228.091 1.5 293.5 68.8383 293.5 152V427C293.5 510.028 228.227 567.5 147.5 567.5C66.9086 567.5 1.5 500.162 1.5 417V152Z"
						stroke="white"
						strokeWidth="3"
					/>
					<path
						d={`M${704.674 + offset} 556.5L${764.344 + offset} 12.5H${767.326 - offset}L${707.656 -
							offset} 556.5H${704.674 + offset}Z`}
						stroke="white"
						strokeWidth="3"
					/>
				</g>
			</svg>
		</div>
	);
};
export default Logo;
