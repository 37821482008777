import React, { useMemo, useEffect, useState } from 'react';
import classnames from 'classnames';

import { useWindowSize } from '../utils/hooks';

import css from './Background.css';

export const Background = ({ video, opacity }) => {
	const win = useWindowSize();
	const size = useMemo(() => {
		if (win.width < 600) return '720w';
		if (win.width < 900) return '720';
		return '1080';
	}, [win]);

	const [loaded, setLoaded] = useState(false);

	useEffect(() => {
		let timer;
		if (video) {
			const poster = new Image();
			poster.onload = () => {
				setLoaded(true);
			};
			poster.src = `${video}.poster.jpg`;
			timer = setTimeout(() => setLoaded(true), 1000);
		}
		return () => clearTimeout(timer);
	}, []);

	return (
		<div className={css.root}>
			<video
				className={css.video}
				style={{ opacity: loaded ? opacity || 1 : 0 }}
				muted
				playsInline
				autoPlay
				loop
				poster={`${video}.poster.jpg`}>
				<source src={`${video}.${size}.mp4`} type="video/mp4" />
				<source src={`${video}.${size}.webm`} type="video/webm" />
			</video>
		</div>
	);
};
