import React, { useContext, useEffect, useState } from 'react';
import classnames from 'classnames';
import { NavLink, useLocation } from 'react-router-dom';

import { store } from '../store';
import { content } from '../utils/content';

import css from './Nav.css';

export const Nav = ({ className }) => {
	const location = useLocation();
	const [open, setOpen] = useState();
	const { state, setState } = useContext(store);

	const toggleOpen = () => {
		setOpen((open) => !open);
	};

	const close = () => {
		setOpen(false);
	};

	useEffect(() => {
		const current = content.find((i) => i.attributes.slug == location.pathname);

		if (current) {
			setState({
				showLogo: !!current.showLogo,
				playbackRateOverride: current.playbackRate,
				videoFile: current.videoFile || state.videoFile,
			});
		} else {
			setState({ playbackRateOverride: 0.1 });
		}
	}, [location]);

	return (
		<>
			<div className={css.top}>
				<ul>
					{content.map((section, index) => {
						if (section.attributes.hidden) return null;
						return (
							<li key={`top-nav-item-${index}`}>
								<NavLink
									className={classnames(css.item, {
										[css.center]: section.attributes.center,
										[css.hidden]: '/' === section.attributes.slug,
									})}
									exact={section.exact}
									activeClassName={css.active}
									to={section.attributes.slug}>
									{section.attributes.title}
								</NavLink>
							</li>
						);
					})}
				</ul>
			</div>
			<nav className={classnames(css.bottom, { [className]: className })}>
				<ul className={classnames({ [css.open]: open })}>
					{content.map((section, index) => {
						if (section.attributes.hidden) return null;
						return (
							<li key={`nav-item-${index}`}>
								<NavLink
									className={classnames(css.item, { [css.center]: section.attributes.center })}
									onClick={close}
									exact={section.exact}
									activeClassName={css.active}
									to={section.attributes.slug}>
									{section.attributes.title}
								</NavLink>
							</li>
						);
					})}
				</ul>
			</nav>
			<div className={css.burger} onClick={toggleOpen} />
		</>
	);
};
