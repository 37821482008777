import React from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { ScrollTop } from './components/ScrollTop';
import { Head } from './components/Head';
import { Nav } from './components/Nav';
import { Project } from './pages/Project';
import { Home } from './pages/Home';

import { content, getProjectBySlug } from './utils/content';
import { useYouTubeApi } from './utils/hooks';

import css from './App.css';

const App = (props) => {
	useYouTubeApi();
	return (
		<div className={css.root}>
			<Head content={content} />
			<Switch>
				{content.map((section, index) => {
					if (section.attributes.hidden) return null;
					const Page = section.component;
					return (
						<Route
							key={`route-${index}`}
							exact
							path={section.attributes.slug}
							render={(props) => <Page {...props} key={index} data={section} />}
						/>
					);
				})}
				<Route
					exact
					path="/work/:slug"
					render={(props) => <Project {...props} data={getProjectBySlug(props.match.params.slug)} />}
				/>
			</Switch>
			<Route path="/" exact component={Home} />
			<Nav />
			<ScrollTop />
		</div>
	);
};

export default withRouter(App);
