import React, { useState, useEffect } from 'react';

export const useWindowSize = () => {
	const [windowSize, setWindowSize] = useState({
		width: undefined,
		height: undefined,
	});

	useEffect(() => {
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		window.addEventListener('resize', handleResize);
		handleResize();

		return () => window.removeEventListener('resize', handleResize);
	}, []);
	return windowSize;
};

export const useCheckIfTouch = () => {
	const [touch, setTouch] = useState();
	useEffect(() => {
		const touchOn = () => setTouch(true);
		window.addEventListener('touchstart', touchOn, { once: true });
		() => {
			window.removeEventListener('touchstart', touchOn);
		};
	}, []);

	return touch;
};

export const useDrawLoop = (fps = 60, play = true) => {
	const [frameCount, setFrameCount] = useState(0);
	useEffect(() => {
		let raf,
			lastFrame,
			numFrames = 0;

		// If play is not true, disable
		if (!play) {
			cancelAnimationFrame(raf);
			return;
		}

		function onFrame() {
			const now = Date.now();
			if ((now - lastFrame) / (1000 / fps) >= 1) {
				numFrames++;
				setFrameCount(numFrames);
				lastFrame = now;
			}
			loop();
		}

		function loop() {
			raf = requestAnimationFrame(onFrame);
		}

		lastFrame = Date.now();
		loop();

		return () => {
			cancelAnimationFrame(raf);
		};
	}, [fps, play]);

	return frameCount;
};

export const useYouTubeApi = () => {
	const [loaded, setLoaded] = useState();
	useEffect(() => {
		window.onYouTubeIframeAPIReady = () => {
			setLoaded('true');
		};
		// Load the IFrame Player API code asynchronously.
		const tag = document.createElement('script');
		tag.src = 'https://www.youtube.com/player_api';
		const firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
	}, []);
	return loaded;
};
