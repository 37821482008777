export const map = (n, start1, stop1, start2, stop2, ease) => {
	let prog = Math.min(1, Math.max(0, (n - start1) / (stop1 - start1)));
	if (ease) {
		prog = ease(prog);
	}
	return prog * (stop2 - start2) + start2;
};

export function uid(prefix = 'comp') {
	return (
		prefix +
		'-' +
		Math.random()
			.toString(36)
			.substring(2, 16)
	);
}
