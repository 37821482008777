import React, { useState, useRef } from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import css from './Projects.css';
import { Link, useHistory } from 'react-router-dom';
import { map } from '../utils';
import { useCheckIfTouch } from '../utils/hooks';
import { Background } from '../components/Background';

export const Projects = (props) => {
	const history = useHistory();
	const [current, setCurrent] = useState(false);
	const { attributes } = props.data;
	const isTouch = useCheckIfTouch();

	return (
		<>
			<Helmet>
				<title>001 / work</title>
			</Helmet>
			<div className={css.root}>
				<ul
					className={css.list}
					onMouseLeave={() => {
						!isTouch && setCurrent(false);
						return true;
					}}>
					{attributes.projects.map((project) => {
						const projectUrl = `/work/${project.slug}`;
						const isCurrent = current === project.slug;
						return (
							<li
								key={`project-${project.slug}`}
								className={classnames(css.item, {
									[css.hidden]: current && !isCurrent,
									[css.active]: current && isCurrent,
								})}>
								<h2
									className={css.name}
									onTouchStart={() => setCurrent(project.slug)}
									onTouchEnd={() => setCurrent(false)}
									onMouseEnter={() => {
										!isTouch && setCurrent(project.slug);
										return true;
									}}
									onClick={() => {
										history.push(projectUrl);
									}}>
									{project.name}
								</h2>
								<figure className={css.thumb}>
									<div
										className={css.container}
										style={{ paddingBottom: `${100 / (project.ratio || 1.4)}%` }}></div>
									<div
										className={css.img}
										style={{
											backgroundImage: `url(//img.youtube.com/vi/${project.youtubeId}/hqdefault.jpg)`,
										}}
									/>
								</figure>
							</li>
						);
					})}
				</ul>
			</div>
			<Background video={attributes.video} opacity={attributes.opacity} />
		</>
	);
};
