import React, { useRef, useState, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import { useDrawLoop } from '../utils/hooks';
import { map } from '../utils/index';
import { Link } from 'react-router-dom';

import { Background } from '../components/Background';

import css from './Home.css';

import { store } from '../store';

import Logo from '../components/Logo';

const movement = 0.003;
const slowDown = 0.1;
const bringBack = 0.002;

export const Home = (props) => {
	const { state, setState } = useContext(store);
	const { videoFile } = state;
	const offset = useRef(0);
	const velocity = useRef(0);
	const acceleration = useRef(0);
	const frameNum = useDrawLoop();

	useEffect(() => {
		const handleMove = (e) => {
			acceleration.current += e.movementX * movement;
		};
		document.addEventListener('mousemove', handleMove);
		return () => document.removeEventListener('mousemove', handleMove);
	}, []);

	// Update the movement every frame
	acceleration.current += velocity.current * slowDown * -1; // slow down with velocity
	acceleration.current += offset.current * bringBack * -1; // bring back to norm
	velocity.current += acceleration.current;
	offset.current += velocity.current;
	acceleration.current = 0;

	return (
		<div>
			<Helmet>
				<title>001</title>
			</Helmet>
			<Background video="/assets/video/reel" />
			{<Logo offset={offset.current} className={css.logo} />}
		</div>
	);
};
