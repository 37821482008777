import React, { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollTop = () => {
	const { pathname } = useLocation();
	const last = useRef();

	useEffect(() => {
		if (last.current && last.current != pathname) {
			window.scrollTo(0, 0);
		}
		last.current = pathname;
	}, [pathname]);
	return null;
};
