import React, { useRef, useEffect, useContext } from 'react';
import { Helmet } from 'react-helmet';

import css from './Project.css';

import { store } from '../store';

export const Project = (props) => {
	const { state, setState } = useContext(store);

	const { data } = props;

	const player = useRef();

	useEffect(() => {
		if (!window.YT) return;
		player.current = new window.YT.Player(css.youtube, {
			height: 'auto',
			width: 'auto',
			videoId: data.youtubeId,
			autoplay: true,
			modestbranding: 1,
			enablejsapi: 1,
			controls: 0,
			widget_referrer: window.location.origin,
			origin: window.location.origin,
		});
	}, [data, window.YT]);

	return (
		<div className={css.root}>
			<Helmet>
				<title>001 / work / {data.name}</title>
			</Helmet>
			<div className={css.container}>
				<div className={css.video} style={{ paddingBottom: `${100 / data.ratio}%` }}>
					<div id={css.youtube} />
				</div>
			</div>
		</div>
	);
};
