import abilities from '../content/abilities.md';
import clients from '../content/clients.md';
import contact from '../content/contact.md';
import projects from '../content/projects.md';
import ourWhy from '../content/our-why.md';
import who from '../content/who.md';

import { Home } from '../pages/Home';
import { Text } from '../pages/Text';
import { Projects } from '../pages/Projects';
import { Clients } from '../pages/Clients';

export const content = [
	{
		attributes: { title: '001', slug: '/', center: true },
		exact: true,
		showLogo: true,
		component: () => null,
	},
	{ ...abilities, component: Text },
	{ ...projects, component: Projects },
	{ ...clients, component: Clients },
	{ ...ourWhy, component: Text },
	{ ...who, component: Text },
	{ ...contact, component: Text },
];

export const getProjectBySlug = (slug) => {
	if (projects.attributes.hidden) return false;
	return projects.attributes.projects.find((project) => project.slug === slug);
};
