import React from 'react';
import { Helmet } from 'react-helmet';

export const Head = ({ content }) => {
	return (
		<Helmet>
			<link rel="icon" href={require('../content/assets/favicon-16.png').src} sizes="16x16" />
			<link rel="icon" href={require('../content/assets/favicon-32.png').src} sizes="32x32" />
			<link rel="icon" href={require('../content/assets/favicon-128.png').src} sizes="128x128" />
			<link rel="icon" href={require('../content/assets/favicon-196.png').src} sizes="196x196" />

			<link rel="shortcut icon" sizes="196x196" href={require('../content/assets/favicon-196.png').src} />

			<link rel="apple-touch-icon" href={require('../content/assets/favicon-196.png').src} sizes="196x196" />

			<link rel="prefetch" href={`/assets/video/reel.poster.jpg`} as="image" />
			{content.map((section, index) => {
				return section.attributes.video ? (
					<link key={index} rel="prefetch" href={`${section.attributes.video}.poster.jpg`} as="image" />
				) : null;
			})}
		</Helmet>
	);
};
