import React, { createContext, useState } from 'react';

const initialState = {};
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ({ children }) => {
	const [state, _setState] = useState(initialState);
	const setState = (newState) => {
		_setState((s) => {
			let ss;
			if (typeof newState === 'function') {
				ss = newState(s);
			} else {
				ss = newState;
			}
			return { ...s, ...ss };
		});
	};
	return <Provider value={{ state, setState }}>{children}</Provider>;
};

export { store, StateProvider };
