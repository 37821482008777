import React, { useState } from 'react';
import css from './Clients.css';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';

import { map } from '../utils';
import { Background } from '../components/Background';

export const Clients = (props) => {
	const { attributes } = props.data;
	return (
		<>
			<Helmet>
				<title>001 / clients</title>
			</Helmet>
			<div className={css.root}>
				<ul className={css.list}>
					{attributes.clients.map((client, index) => {
						let Logo = () => null;
						try {
							Logo = require(`../content/assets/logos/${client.logo}`);
						} catch (e) {
							console.log(`Logo file "${client.logo}" not found`);
						}
						return (
							<li key={`client-${index}`} className={css.item}>
								<a href={client.url} target="_blank">
									<Logo className={css.logo} alt={client.name} width={null} height={null} />
								</a>
							</li>
						);
					})}
				</ul>
			</div>
			<Background video={attributes.video} opacity={attributes.opacity} />
		</>
	);
};
