import React from 'react';
import classnames from 'classnames';
import { Helmet } from 'react-helmet';

import { Background } from '../components/Background';
import css from './Text.css';

export const Text = (props) => {
	const { react: Content, attributes } = props.data;
	const className = attributes.slug.replace(/^\/|\/$/, '').replace(/\//g, '-');

	return (
		<>
			<Helmet>
				<title>001 / {attributes.title}</title>
			</Helmet>
			<div className={classnames(css.root, { [css[className]]: css[className] })}>
				<div className={css.text}>
					<Content />
				</div>
			</div>
			<Background video={attributes.video} opacity={attributes.opacity} />
		</>
	);
};
